.statistic__container {
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(180deg, var(--from-grad), var(--to-grad));
  color: white;
}

.statistic__title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.statistic__subtitle {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.statistic__row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
}

.statistic__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.statistic__icon {
  margin-bottom: 10px;
}

.statistic__count-wrapper {
  width: 100px;
  overflow: hidden; 
}

.statistic__count {
  margin: 10px 0;
  font-size: 2rem;
  display: inline-block;
}

.statistic__description {
  font-size: 1rem;
  color: #dcdde1;
}

@media (max-width: 806px) and (min-width: 601px) {
  .statistic__row {
    gap: 40px;
  }

  .statistic__column {
    flex: 1 1 45%;
    max-width: 45%; 
  }
}
