.feature-section {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    overflow: hidden;
    width: 100%;
    min-height: 50vh;
    background: linear-gradient(0deg, var(--from-grad), var(--to-grad));
}

.feature-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, var(--to-grad), rgba(0, 0, 0, 0));
    pointer-events: none;
}

.feature-title-container {
    padding: 0 20px 20px 20px;
    text-align: center;
    color: white;
}

.feature-title-container h2 {
    font-size: 2em;
}

.feature-title-container p {
    margin: 0;
    font-size: 1.3em;
    text-align: left;
}

.feature-wrapper {
    flex: 0 0 44%;
}

.feature-box-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    text-align: center;
    color: #fff;
    margin: 0 6% 0 12%;
}

.feature-card {
    perspective: 1000px;
    width: 100%;
    max-width: 300px;
    height: 200px;
    margin: 0 auto;
}

.feature-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.feature-card:hover .feature-card-inner {
    transform: rotateY(180deg);
}

.feature-card-front, .feature-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.feature-card-front {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feature-card-back {
    background-color: #910d57;
    color: white;
    padding: 20px;
    border-radius: 10px;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.feature-card-icon {
    font-size: 30px;
    color: #fff;
    margin: 20px 0;
}

.feature-card-front h3 {
    margin: 0;
}

.feature-card-back p {
    font-size: 0.85em;
    text-align: initial;
    line-height: 1.5;
    font-weight: bold;
}

@media (min-width: 1200px) and (max-width: 1507px) {
    .feature-card-back p {
        font-size: 0.78em;
    }
}

@media (max-width: 1200px) {
    .feature-box-area {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1200px) {
    .feature-section {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
    .feature-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        padding: 0 10%;
    }
    .feature-wrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
    }
    .feature-box-area {
        gap: 20px;
        margin: 0;
    }
    .feature-card {
        max-width: 90%;
    }
}

@media (min-width: 768px) and (max-width: 887px) {
    .feature-card-back p {
        font-size: 0.74em;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .feature-box-area {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
    }

    .feature-card {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .feature-section {
        padding: 20px;
    }
    .feature-title-container h2 {
        font-size: 1.8em;
    }
    .feature-title-container p {
        font-size: 17px;
    }
    .feature-card-front h3 {
        font-size: 1.3em;
    }
    .feature-card-back p {
        font-size: 0.9em;
    }
    .feature-card-icon {
        font-size: 35px;
    }
    .feature-wrapper {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .feature-section {
        padding: 10px;
    }
    .feature-title-container {
        padding: 0;
        margin-bottom: 20px;
    }
    .feature-box-area {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .feature-title-container h2 {
        font-size: 1.4em;
    }
    .feature-title-container p {
        font-size: 17px;
        margin: 0 10%;
        text-align: justify;
    }
    .feature-card-front h3 {
        font-size: 1.2em;
    }
    .feature-card-back p {
        font-size: 0.8em;
    }
    .feature-card-icon {
        font-size: 35px;
    }
    .feature-card {
        max-width: 60%;
        height: 150px;
    }
}

