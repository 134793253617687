.reviews__section {
  background: linear-gradient(0deg, var(--from-grad), var(--to-grad));
  padding: 40px 20px;
}

.reviews__stars {
  display: flex;
  margin-top: 35px;
}

.reviews__star {
  color: red;
  font-size: 1.2rem;
}

.reviews__star--filled {
  color: red;
}

.reviews__star:not(:last-child) {
  margin-right: 5px;
}

.reviews__item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 10px;
  cursor: grab;
  user-select: none;
  position: relative;
}

.reviews__item:active {
  cursor: grabbing;
}

.reviews__heading {
  text-align: center;
  margin-top: 0;
  color: white;
}

.reviews__work {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 350px;
  padding: 60px 35px 5px;
  text-align: center;
  height: 285px;
}

.reviews__header {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviews__customer-image {
  width: 95px;
  height: 95px;
  background-color: #ddd;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  left: -16px;
  z-index: 1;
  border: 3px solid #ff008f;
}

.reviews__customer-name {
  position: absolute;
  top: 34px;
  right: 12px;
  transform: translate(13%, -50%);
  z-index: 2;
  border-radius: 0 15px 0 15px;
  width: calc(100% - 60px);
  max-width: 400px;
  text-align: left;
  padding: 25px 0 25px 30px;
  background-image: linear-gradient(to right, #910d57, #e88bde);
  color: white;
  font-weight: bold;
}

.reviews__customer-name::before,
.reviews__customer-name::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.reviews__customer-name::before {
  border-color: transparent transparent transparent transparent;
}

.reviews__customer-name::after {
  border-color: transparent transparent transparent transparent;
}

.reviews__customer-comment {
  padding: 20px 0 30px 0;
  border-radius: 5px;
  text-align: left;
  font-weight: bold;
}

.reviews__carousel-wrapper {
  position: relative;
  width: 100%;
}

.reviews__carousel-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.reviews__carousel-dots {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex: 1;
}

.reviews__carousel-dots-item {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  cursor: pointer;
}

.reviews__customer-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}


@media (max-width: 1250px) {
  .reviews__work {
    width: 245px;
    padding: 30px 20px 10px;
  }

  .reviews__customer-name {
    padding: 15px 0 15px 20px;
    top: 24px;
    right: -1px;
  }

  .reviews__stars {
    margin-top: 45px;
  }

  .reviews__customer-comment {
    padding: 10px 0 20px 0;
  }
}

@media (max-width: 825px) {
  .reviews__customer-name {
    right: -2px;
  }
}

@media (max-width: 800px) {
  .reviews__customer-name {
    right: -3px;
  }
}

@media (max-width: 768px) {
  .reviews__heading {
    font-size: 1.5rem;
  }

  .reviews__star {
    font-size: 1rem;
  }

  .reviews__customer-name {
    font-size: 0.9rem;
    right: -6px;
    top: 23px;
    width: calc(100% - 59px);
  }

  .reviews__customer-comment {
    font-size: 0.96rem;
  }
}

@media (max-width: 700px) {
  .reviews__work {
    width: 300px;
    height: 260px;
    padding: 50px 20px 10px;
  }

  .reviews__customer-image {
    width: 90px;
    height: 90px;
    top: -40px;
  }

  .reviews__customer-name {
    top: 24px;
    right: 4px;
    width: calc(100% - 70px);
    font-size: 1rem;
  }

  .reviews__customer-comment {
    padding: 25px 0 25px 0;
    font-size: 1rem;
  }

  .reviews__stars {
    margin-top: 20px;
  }
}
