.flag-wrapper {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-wrapper svg {
  width: 100%;
  height: 100%;
}

.language-dropdown {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}

.language-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  top: 40px;
  right: 5px;
  border-radius: 5px;
  padding: 10px 0 7px 7px;
}

.language-dropdown:hover .language-dropdown-content,
.language-dropdown-content:hover {
  display: block;
}

.flag-wrapper:hover {
  background-color: transparent;
  border: none;
  transform: none;
}

.language-dropdown-content .language-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.language-dropdown-content .language-item:not(:last-child) {
  border-bottom: 1px solid #e0e0e0; /* Line between all items except the last one */
}

.language-dropdown-content .language-item svg {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.language-dropdown-content .language-item span {
  color: black;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Vazirmatn';
}
