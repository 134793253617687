.toast-success {
  background-color: #006317;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  padding: 16px;
}

.toast-error {
  background-color: #a71a28;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  padding: 16px;
}

.toast-progress-success {
  background-color: #155724;
}

.toast-progress-error {
  background-color: #721c24;
}

.toast-fa {
  font-family: 'Vazirmatn' !important;
  text-align: right !important;
  direction: rtl !important;
}


