.contact__outer-container {
  padding: 20px 5% 0 5%;
  background: linear-gradient(0deg, var(--from-grad), var(--to-grad));
}

.contact__form-container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background: transparent;
  color: white;
}

.contact__form-section,
.contact__info {
  width: 45%;
}

h2, h3 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact__form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contact__form-group {
  width: 48%;
  position: relative;
  margin-bottom: 30px;
}

.contact__form-group.contact__message {
  width: 48%;
}

.contact__form-group input,
.contact__form-group textarea {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  color: white;
  font-size: 16px;
}

.contact__form-group textarea[placeholder] {
  margin-top: 35px;
}

.contact__form-group .contact__message {
  width: 200%;
  height: 100px;
}

.contact__input-title {
  position: absolute;
  top: 12px;
  color: white;
  opacity: 1;
  transition: all 0.3s ease;
}

.contact__input-title.contact__focused {
  top: -10px;
  font-size: 12px;
  color: #ff007f;
}

.contact__input-title.left-position {
  left: 10px;
}

.contact__input-title.right-position {
  right: 10px;
}

.contact__message .contact__input-title.contact__focused {
  top: 20px !important;
}

.contact__form-group input:focus,
.contact__form-group textarea:focus {
  outline: none;
  border-bottom: 1px solid #ff007f;
}

form button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #ff007f;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

form button:hover {
  background-color: #e60073;
}

.contact__info h3 {
  margin: 0;
}

.contact__info p {
  margin: 20px 0 35px 0;
  text-align: left;
}

.contact__info a {
  color: #ff007f;
  text-decoration: none;
}

.contact__info a:hover {
  text-decoration: underline;
}

.contact__social-links a {
  color: white;
  text-decoration: none;
  margin-right: 10px;
}

.contact__social-links a:hover {
  text-decoration: underline;
}

.contact__info b {
  color: #ff1c68;
}

.contact__details {
  display: inline-block;
  text-align: left;
}

@media (max-width: 800px) {
  .contact__form-container {
    flex-direction: column;
    align-items: center;
  }
  
  .contact__form-section,
  .contact__info {
    width: 100%;
  }
  
  .contact__form-section,
  .contact__info h2 {
    text-align: center;
  }
  .contact__info {
    margin-top: 40px;
  }

  .contact__info p {
    margin: 20px 0 15px 0;
  }
}

@media (max-width: 480px) {
  .contact__form-group {
    width: 100%;
  }
  .contact__form-row {
    flex-direction: column;
  }
}
