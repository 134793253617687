.footer {
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px 0;
  bottom: 0;
  left: 0;
}

.footer p {
  margin: 0;
}
