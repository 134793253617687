.banner {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.banner__image {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: top;
}

.banner__text {
    position: absolute;
    top: 44%;
    left: 13%;
    transform: translateY(-50%);
    color: white;
    pointer-events: none;
    text-align: center;
}

.banner__company-name {
    font-size: 7.8em;
    margin: 0;
}

.banner__slogan {
    font-size: 1.3rem;
    margin: 5px 0 0;
    font-weight: bold;
}

.banner__overlay-top {
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.banner__overlay-bottom {
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, var(--to-grad, rgba(0, 0, 0, 1)) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
}


@media (max-width: 1700px) {
    .banner__company-name {
        font-size: 7.5em;
    }
    .banner__text {
        left: 11%;
    }
}

@media (max-width: 1400px) {
    .banner__company-name {
        font-size: 6.5em;
    }
    .banner__text {
        left: 11%;
    }
}

@media (max-width: 1200px) {
    .banner__company-name {
        font-size: 3.7em;
    }
    .banner__text {
        left: 13%;
    }
    .banner__slogan {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .banner__text {
        display: none;
    }
}