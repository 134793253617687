.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 6%;
    width: calc(90% - 30px);
    padding: 10px 20px;
    background: transparent;
    color: white;
    z-index: 1;
    transition: top 0.3s, background 0.3s, left 0.3s, width 0.3s;
}

.navbar__scrolled {
    background: rgba(0, 0, 0, 1);
}

.navbar__sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 10% 10px 10%;
    z-index: 10;
    transition: top 0.3s, background 0.3s, left 0.3s, width 0.3s;
}

.navbar__left {
    display: flex;
    align-items: center;
    transition: all 0.3s;
}

.navbar__logo {
    font-size: 1.5em;
    font-weight: bold;
    margin-right: 20%;
    margin-top: 5px;
    transition: all 0.3s;
}

.navbar__nav {
    display: flex;
    gap: 25px;
    transition: all 0.3s;
}

.navbar__nav a {
    color: white;
    text-decoration: none;
    font-size: 1em;
    transition: all 0.3s;
}

.navbar__login {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 20px;
}

.navbar__right {
    display: flex;
    align-items: center;
    transition: all 0.3s;
    gap: 10px;
}

.navbar__hamburger-icon {
    display: none;
    cursor: pointer;
    z-index: 20;
}

.navbar__message-icon {
    cursor: pointer;
    z-index: 20;
}

.navbar__sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 20px;
    transition: right 0.3s ease-in-out;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: auto;
}

.navbar__sidebar__open {
    right: 0;
}

.navbar__sidebar a {
    color: #f5f5f5;
    text-decoration: none;
    font-size: 1.2em;
    margin: 5px 10px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    box-sizing: border-box;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 10px;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.navbar__sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(10px);
    border-bottom: 2px solid #fff;
}

.navbar__sidebar a::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.4));
    transition: height 0.3s ease;
    transform: scaleX(0);
    transform-origin: center;
}

.navbar__sidebar a:hover::after {
    transform: scaleX(1);
    height: 3px;
}

.navbar__sidebar-overlay {
    display: none;
}

.navbar__sidebar-overlay__open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

.navbar__sidebar.open svg {
    font-size: 1.3em;
}

.navbar__brand {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 25px;
}

.navbar__brand__logo {
    margin-right: 10px;
}

.navbar__brand__text {
    font-size: 25px;
    color: #f5f5f5;
    font-weight: bold;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.navbar__close-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    color: #f5f5f5;
}

@media (max-width: 768px) {
    .navbar__nav {
        display: none;
    }
    .navbar__hamburger-icon {
        display: block;
    }
    .navbar__right svg {
        font-size: 1.3em;
    }
    .navbar__left .navbar__logo img {
        width: 30px;
        height: 30px;
    }
}
