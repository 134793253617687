.management-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, var(--from-grad), var(--to-grad));
    padding: 0 20px;
}

.management-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.management-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.management-main {
    position: relative;
    width: 600px;
    height: 600px;
}

.center-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background: conic-gradient(
        #e5007b 0deg 80deg,
        black 80deg 135deg,
        #e5007b 135deg 180deg,
        black 180deg 240deg,
        #e5007b 240deg 300deg,
        black 300deg 360deg
    );
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.center-circle h4 {
    z-index: 1;
}

.center-circle::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 150px;
    background: #f0f0f0;
    border-radius: 50%;
    z-index: 1;
}

.steps {
    position: relative;
    width: 100%;
    height: 100%;
}

.step {
    position: absolute;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #333;
    padding: 10px;
    clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.step .icon {
    background: #ef233c;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
}

#step1 { top: 14%; left: 39%; transform: rotate(245deg) skewX(-30deg) scale(1, .866); }
#step2 { top: 12%; left: 66.4%; transform: rotate(-48.8deg) skewX(-30deg) scale(1, .866); }
#step3 { top: 23.2%; left: 66.8%; transform: rotate(9.4deg) skewX(-30deg) scale(1, .866); }
#step4 { top: 22%; left: 45%; transform: rotate(65deg) skewX(-30deg) scale(1, .866); }
#step5 { bottom: 6%; left: 19%; transform: rotate(126deg) skewX(-30deg) scale(1, .866); }
#step6 { bottom: 48%; left: 16%; transform: rotate(182deg) skewX(-30deg) scale(1, .866); }

#step-text-1 { position: absolute; z-index: 2; top: 20%; left: 45%; }
#step-text-2 { position: absolute; z-index: 2; top: 37%; right: 23%; }
#step-text-3 { position: absolute; z-index: 2; top: 61%; right: 25%; }
#step-text-4 { position: absolute; z-index: 2; top: 71%; right: 45%; }
#step-text-5 { position: absolute; z-index: 2; top: 57%; left: 22%; }
#step-text-6 { position: absolute; z-index: 2; top: 35%; left: 23%; }

.step-content { transform: rotate(0deg); }

h3 { margin: 10px 0; }

img { width: 40px; height: 40px; }

.triangle {
    position: relative;
    background-color: #f0f0f0;
    text-align: left;
}

.triangle:before,
.triangle:after {
    content: '';
    position: absolute;
    background-color: inherit;
}

.triangle,
.triangle:before,
.triangle:after {
    width: 6em;
    height: 6em;
    border-top-right-radius: 30%;
}

.triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}

.triangle:nth-child(even):before {
    background: radial-gradient(at 235% -92%, #e5007b 223px, #f0f0f0 12px);
}

.triangle:nth-child(odd):before {
    background: radial-gradient(at 235% -92%, black 223px, #f0f0f0 12px);
}

.circle {
    width: 65px;
    height: 65px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.inner-circle {
    width: 50px;
    height: 50px;
    background-color: #e5007b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.circle-1 { top: 8%; left: 44%; }
.circle-2 { top: 29%; right: 13%; }
.circle-3 { bottom: 28%; right: 14%; }
.circle-4 { bottom: 9%; left: 44%; }
.circle-5 { bottom: 28%; left: 13%; }
.circle-6 { top: 29%; left: 13%; }

.circle:nth-child(even) .inner-circle { background-color: black; }

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    font-size: 20px;
    text-align: center;
    color: white;
}

.content p {
    text-align: center;
}

.animated-section {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

@media (max-width: 1300px) {
    .management-content-wrapper {
        flex-direction: column;
    }

    .management-container {
        width: 100%;
    }

    .content {
        width: 100%;
        padding: 20px;
    }
    .content p {
        padding: 0 10%;
    }
}

@media (max-width: 600px) {
    .management-container {
        display: none;
    }

    .content {
        font-size: 17px;
    }
}
